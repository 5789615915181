import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import UserState from './user.reducer';
import { User } from '../model/user.model';

export const getUserState = (state: State) => state.user;

export const selectUser = createSelector(getUserState, (state: UserState) => state?.user);

export const selectUserId = createSelector(selectUser, (user?: User) => user?.id);

export const selectRedirectForNonAdminUsers = createSelector(selectUser, (user?: User) => {
  if (user?.activeRole !== 'role_admin') {
    return '/cockpit';
  }
  return undefined;
});

export const selectIsGisTokenRegistered = createSelector(getUserState, (state: UserState) =>
  state !== undefined && state.registeredGisToken
);

export const selectConfiguration = createSelector(getUserState, (state: UserState) => state.configuration);

export const selectUserReportUrl = createSelector(selectConfiguration, configuration => configuration?.userReportUrl);

export const selectMotd = createSelector(getUserState, (state: UserState) => state?.motd);

export const selectHasPolicy = (policyCode: string) => createSelector(selectUser, user =>
  user !== undefined && user.policies.some(policy => policy === policyCode));

export const selectHasAccess = createSelector(getUserState, (state: UserState) => state.hasAccessToBuilding);


export const selectHasContext = createSelector(
  selectUser,
  user => !!user?.roleContextValues?.some(r => r.roleCode === user?.activeRole)
);

export const selectActiveRoleContextValues = createSelector(
  selectUser,
  user => user?.roleContextValues?.find(r => r.roleCode === user?.activeRole)?.contextValues || []
);
