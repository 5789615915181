<form [formGroup]="userForm" *transloco="let t; read: 'cockpit'">
  <div fxLayout="row">
    <div fxLayout="column" class="general-info">
      <mat-form-field>
        <mat-label>{{ t('lastName') }}</mat-label>
        <input matInput formControlName="lastName" required />
        <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
      </mat-form-field>
      <div>
        <mat-form-field>
          <mat-label>{{ t('firstName') }}</mat-label>
          <input matInput formControlName="firstName" required />
          <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>{{ t('email') }}</mat-label>
          <input matInput formControlName="email" required />
          <mat-error>{{ t('requiredValidEmail') }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>{{ t('phoneNumber') }}</mat-label>
          <input matInput formControlName="phoneNumber" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>{{ t('language') }}</mat-label>
          <mat-select formControlName="language" required>
            <mat-option value="fr">{{ t('french') }}</mat-option>
            <mat-option value="de">{{ t('german') }}</mat-option>
          </mat-select>
          <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
        </mat-form-field>
      </div>
      <sibat-primary-button (clicked)="onSubmit()" [label]="'submit' | transloco"></sibat-primary-button>
    </div>

    <div fxLayout="column" class="role-context">
      <mat-form-field class="role">
        <mat-label>{{ t('role') }}</mat-label>
        <mat-chip-list>
          <mat-chip
              *ngFor="let role of (roles$ | async)"
              selectable
              [class.selected]="userForm.get('roles')?.value.includes(role.code)"
              [disabled]="role.code === 'role_api'"
              (click)="toggleRole(role.code)"
          >
            <mat-icon matChipAvatar *ngIf="isRoleSelected(role.code)">check</mat-icon>
            {{ role.name }}
          </mat-chip>
        </mat-chip-list>
        <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
      </mat-form-field>
      <ng-container formArrayName="roleContextValues">
        <ng-container *ngFor="let contextControl of roleContextValues.controls; let i = index" [formGroupName]="i">
          <mat-form-field class="context">
            <mat-label>{{ t('municipalities') }} : <b>{{ getLabelForRole(contextControl.value.roleCode) }}</b>
            </mat-label>
            <mat-select formControlName="contextValues" multiple panelClass="list-540px"
                        [disabled]="(getContextValuesForSelectedRole(contextControl.value.roleCode.code) | async)?.length === 0">
              <mat-option
                  *ngFor="let contextValue of getContextValuesForSelectedRole(contextControl.value.roleCode.code) | async"
                  [value]="contextValue.geoField">
                {{ contextValue.geoFieldAlias }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>
      <!---***********************************************-->
    </div>
  </div>
</form>
