import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AbstractControlDetail, computeNextControlDate, Control, ControlDetails } from '../model/control.model';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { extendTextArea } from 'src/app/shared/style';
import { replaceNullValueWithUndefined } from 'src/app/shared/form-control';

@Component({
  selector: 'sibat-edit-control-details',
  template: `
    <h3>{{ 'building.control.controlDetailsForm.controlDetails' | transloco }}</h3>
    <form
      data-testid="control-from"
      class="form-container"
      [class.read-only]="readOnly"
      fxLayout="column"
      [formGroup]="controlDetailsForm"
      *transloco="let t; read: 'building.control.controlDetailsForm'"
    >
      <div class="container-small" fxLayout="column">
        <mat-form-field>
          <mat-label>{{ t('designation') }}</mat-label>
          <input matInput formControlName="designation" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ t('landlordOrOperator') }}</mat-label>
          <input matInput formControlName="landlordOrOperator" required="required" />
          <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ t('peoplePresent') }}</mat-label>
          <textarea matInput formControlName="peoplePresent" (keyup)="extendTextArea($event.target)"></textarea>
        </mat-form-field>
        <div class="radio-container">
          <mat-label>{{ t('type')}}</mat-label>
          <mat-radio-group formControlName="type" name="type">
            <mat-radio-button name="type" value="RECEPTION">{{ t('reception') }}</mat-radio-button>
            <mat-radio-button name="type" value="PERIODIC">{{ t('periodic') }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field>
          <mat-label>{{ t('controlDate') }}</mat-label>
          <input
            matInput
            formControlName="date"
            [matDatepicker]="controlDatePicker"
            required="required"
            (focus)="controlDatePicker.open()"
            (dateChange)="preventRequiredDate(controlDate)"
          />
          <mat-datepicker-toggle [disabled]="offline" matSuffix [for]="controlDatePicker"></mat-datepicker-toggle>
          <mat-datepicker disabled="false" #controlDatePicker></mat-datepicker>
          <mat-error *ngIf="controlDate.hasError('matDatepickerParse')">{{ 'errors.invalidDateFormat' | transloco }}
          </mat-error>
          <mat-error *ngIf="controlDate.hasError('required') && !controlDate.hasError('matDatepickerParse')">
            {{ 'errors.requiredField' | transloco }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ t('nextControlDate') }}</mat-label>
          <input
            matInput
            formControlName="nextDate"
            (dateChange)="preventRequiredDate(nextControlDate)"
            [required]="!!controlInterval"
            [matDatepicker]="nextControlDatePicker"
            (focus)="nextControlDatePicker.open()"
          />
          <mat-datepicker-toggle [disabled]="offline" matSuffix [for]="nextControlDatePicker"></mat-datepicker-toggle>
          <mat-datepicker disabled="false" #nextControlDatePicker></mat-datepicker>
          <mat-error
            *ngIf="nextControlDate.hasError('matDatepickerParse')">{{ 'errors.invalidDateFormat' | transloco }}
          </mat-error>
          <mat-error *ngIf="nextControlDate.hasError('required') && !nextControlDate.hasError('matDatepickerParse')">
            {{ 'errors.requiredField' | transloco }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ t('remarks') }}</mat-label>
          <textarea matInput formControlName="remarks" (keyup)="extendTextArea($event.target)"></textarea>
        </mat-form-field>
        <div>
          <sibat-validate-button
            (clicked)="saveData()"
            [disabled]="offline || controlDetailsForm.pristine"
            [label]="t('saveControlDetails')"
            data-testid="save-button"
          >
          </sibat-validate-button>
        </div>
      </div>
    </form>
  `,
  styleUrls: ['edit-control-details.component.scss'],
})
export class EditControlDetailsComponent implements OnChanges, OnDestroy, OnInit {
  @Input() control: Control | undefined;
  @Input() controlInterval: number | undefined;
  @Input() readOnly: boolean;
  @Input() offline: boolean;
  @Output() controlDetailsSaved = new EventEmitter<ControlDetails>();
  @Output() unsavedDetails = new EventEmitter<boolean>();
  formSubscription: Subscription;

  readonly controlDate = this.formBuilder.control(undefined, Validators.required);
  readonly nextControlDate = this.formBuilder.control(undefined);
  readonly peoplePresent = this.formBuilder.control(undefined);
  readonly type = this.formBuilder.control(undefined);
  readonly landlordOrOperator = this.formBuilder.control(undefined, Validators.required);
  readonly remarks = this.formBuilder.control(undefined);
  readonly designation = this.formBuilder.control(undefined);
  readonly controlDetailsForm = this.formBuilder.group(
    {
      designation: this.designation,
      landlordOrOperator: this.landlordOrOperator,
      date: this.controlDate,
      nextDate: this.nextControlDate,
      remarks: this.remarks,
      peoplePresent: this.peoplePresent,
      type: this.type,
    } as AbstractControlDetail,
    { validators: Validators.required }
  );

  constructor(private formBuilder: UntypedFormBuilder) {
    this.formSubscription = this.controlDetailsForm.statusChanges
      .pipe(
        map(() => this.controlDetailsForm.pristine),
        distinctUntilChanged()
      )
      .subscribe(pristine => {
        this.unsavedDetails.emit(!pristine);
      });
  }

  ngOnInit() {
    if (!!this.controlInterval) {
      this.nextControlDate.setValidators(Validators.required);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.control) {
      this.setValuesForOngoingControl(this.control);
    }
    if (changes.offline) {
      if (changes.offline.currentValue) {
        this.controlDetailsForm.disable();
      } else {
        this.controlDetailsForm.enable();
      }
    }
  }

  extendTextArea(element) {
    extendTextArea(element);
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
  }

  setValuesForOngoingControl(control: Control | undefined) {
    if (control) {
      const currentControlDate = new Date(control.date);
      this.controlDate.setValue(currentControlDate);
      const nextControlDate = computeNextControlDate(control, this.controlInterval);
      this.nextControlDate.setValue(nextControlDate);
      this.landlordOrOperator.setValue(control.landlordOrOperator);
      this.remarks.setValue(control.remarks);
      this.peoplePresent.setValue(control.peoplePresent);
      this.designation.setValue(control.designation);
      this.type.setValue(control.type);
    } else {
      this.controlDate.reset();
      this.nextControlDate.reset();
      this.landlordOrOperator.reset();
      this.remarks.reset();
      this.peoplePresent.reset();
      this.designation.reset();
      this.type.reset();
    }
  }

  saveData(): void {
    this.controlDetailsSaved.emit(this.controlDetailsForm.value);
    this.controlDetailsForm.markAsPristine();
  }

  preventRequiredDate(formControl: UntypedFormControl) {
    replaceNullValueWithUndefined(formControl);
  }
}
