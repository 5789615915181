<form [formGroup]="userForm" *transloco="let t; read: 'cockpit'">
  <div fxLayout="row">
    <div class="general-info" fxLayout="column">
      <div class="form-field mat-form-field-wrapper">
        <mat-slide-toggle formControlName="enabled" color="warn">{{ t('enabled') }}</mat-slide-toggle>
      </div>

      <ng-container *ngFor="let field of formFields">
        <mat-form-field>
          <mat-label>{{ t(field.label) }}</mat-label>
          <input *ngIf="field.type === 'input'" matInput [formControlName]="field.name" required/>
          <mat-select *ngIf="field.type === 'select'" formControlName="language" required>
            <mat-option *ngFor="let option of field.options" [value]="option.value">{{ t(option.label) }}</mat-option>
          </mat-select>
          <mat-error>{{ field.errorMessage | transloco }}</mat-error>
        </mat-form-field>
      </ng-container>

      <sibat-primary-button (clicked)="onSubmit()" [label]="'cockpit.submit' | transloco"></sibat-primary-button>
    </div>

    <div fxLayout="column" class="role-context">
      <mat-form-field class="role">
        <mat-label>{{ t('role') }}</mat-label>
        <mat-chip-list>
          <mat-chip
              *ngFor="let role of (roles$ | async)"
              selectable
              [class.selected]="userForm.get('roles')?.value.includes(role.code)"
              [disabled]="role.code === 'role_api'"
              (click)="toggleRole(role.code)"
          >
            <mat-icon matChipAvatar *ngIf="isRoleSelected(role.code)">check</mat-icon>
            {{ role.name }}
          </mat-chip>
        </mat-chip-list>
        <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
      </mat-form-field>

      <ng-container formArrayName="roleContextValues">
        <ng-container *ngFor="let contextControl of roleContextValues.controls; let i = index" [formGroupName]="i">
          <mat-form-field class="context">
            <mat-label>{{ t('municipalities') }} : <b>{{ getLabelForRole(contextControl.value.roleCode) }}</b></mat-label>
            <mat-select formControlName="contextValues" multiple panelClass="list-540px"
                        [disabled]="(getContextValuesForSelectedRole(contextControl.value.roleCode) | async)?.length === 0">
              <mat-option
                  *ngFor="let contextValue of getContextValuesForSelectedRole(contextControl.value.roleCode) | async"
                  [value]="contextValue.geoField">
                {{ contextValue.geoFieldAlias }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
