<div class="timeline__item dot-{{ typology?.color }}" *transloco="let t; read: 'building.history'">
  <div class="building-event-bullet-point"></div>
  <div class="timeline__divider"></div>
  <div class="timeline__content">
    <div class="timeline__date">
      <div class="date-label">{{ date | date: 'dd.MM.yyyy' }}</div>
    </div>
    <div class="timeline__section" *ngIf="typology; else controlTemplate">
      <div>
        <span>{{ t('type', { type: 'typology' }) }}</span
        >,
        <span *ngIf="typology.source === 'automatique'">{{ t('automaticClassification') }}&nbsp;</span>
        <span *ngIf="typology.previousColor">{{ t('fromColor', { color: t('colors', { color: typology.previousColor }) }) }}&nbsp;</span>
        <span class="color-label" innerHTML="{{ t('toColor', { color: t('colors', { color: typology.color }) }) }}"></span>
        <span *ngIf="typology.user">, {{ t('byUser', { user: typology.user | displayName }) }} </span>
      </div>
      <div *ngIf="typology.justification">
        <em>{{ typology.justification }}</em>
      </div>
    </div>
    <ng-template #controlTemplate>
      <div class="timeline__section">
        <div>
          <span>{{ t('type', { type: 'control' }) }}</span>
          <ng-container *ngIf="!control.reportFileName">
            {{ t('byUser', { user: control.user | displayName }) }},
          </ng-container>
        </div>
        <div class="buttons">
          <sibat-tertiary-button
            [label]="'building.control.previewControlReport' | transloco"
            (clicked)="previewControlReport()"
          ></sibat-tertiary-button>
          <sibat-tertiary-button
            *ngIf="canDisplayDefects$ | async"
            data-testid="defectsLink"
            [label]="t(control.isCompliant ? 'defectComplianceList' : 'followDefectCompliance')"
            (clicked)="displayControlDefects(control.id)"
          ></sibat-tertiary-button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
