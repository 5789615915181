import { Building } from '../building/model/building.model';
import { User } from './model/user.model';
import { selectHasPolicy } from './store/user.selector';


export const authorization = (store, user?: User) => ({
  authorizedForBuilding: (building?: Building): boolean => {
    if (!building || !user) {
      return false;
    }

    let isAuthorized = false;

    store.select(selectHasPolicy('building.files.get')).subscribe((hasPolicy: boolean) => {
      isAuthorized =
        hasPolicy ||
        (
          user.activeRole === 'role_scpi' && user.roleContextValues.some(
            roleContext =>
              roleContext.roleCode === 'role_scpi' &&
              roleContext.contextValues.includes(building.municipalityFosNr)
        )) ||
        user.activeRole === 'role_cecb';
    }).unsubscribe();

    return isAuthorized;
  }
});
